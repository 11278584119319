@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';

.container {
  align-items: center;
  display: flex;
  gap: 8px 8px;
  flex-grow: 1;

  .placePickersContainer {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    gap: 0 8px;
    height: 64px;
    justify-content: center;

    .placePickerContainer {
      height: 100%;
      position: relative;
      width: 50%;

      .inputSelector {
        --input-selector-focus-width: 560px;
      }

      .category {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0 24px;

        &.with8TopMargin {
          margin-top: 8px;
        }

        &.with16TopMargin {
          margin-top: 16px;
        }
      }

      .place {
        align-items: center;
        display: flex;
        gap: 0 8px;
        height: 48px;
        padding: 0 28px;

        .icon {
          @include search-form-icons;
          flex-shrink: 0;
        }

        .labelAndCode {
          align-items: center;
          display: flex;
          gap: 0 8px;
          justify-content: space-between;
          width: calc(100% - 32px);

          .label {
            @include ellipsis;
            font-size: 16px;
            line-height: 24px;
          }

          .code {
            color: $neutral-600;
            flex-shrink: 0;
            font-size: 16px;
            line-height: 24px;
          }
        }

        &.with8TopMargin {
          margin-top: 8px;
        }

        &.selected,
        &.active,
        &:hover {
          background-color: $green-100;

          .icon {
            &.airportGrey {
              background-position: 0px -25px; // Is background-position of airportGreen
            }

            &.cityGrey {
              background-position: -50px -100px; // Is background-position of cityGreen
            }
          }

          .labelAndCode {
            .label,
            .code {
              color: $green-500;
            }
          }
        }
      }

      .popularCities {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 24px 0;

        .popularCity {
          align-items: center;
          display: flex;
          height: 44px;
          padding: 0 8px;
          width: 25%;

          > div {
            @include ellipsis;
            font-size: 16px;
            line-height: 24px;
          }

          &:hover {
            background-color: $green-100;

            > div {
              color: $green-500;
            }
          }
        }
      }

      .inspirationalShoppingWrapper {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 8px;
        padding: 16px 16px 16px 20px;

        svg {
          color: $neutral-600;
          flex-shrink: 0;
          height: 24px;
          width: 24px;

          path {
            fill: $neutral-600;
          }
        }

        .text {
          flex-grow: 1;
          font-size: 16px;
          line-height: 24px;

          .header {
            align-items: center;
            display: flex;
            gap: 8px;

            span {
              background: $orange-300;
              border-radius: 100px;
              color: $neutral-white;
              display: inline-block;
              font-size: 12px;
              font-weight: 700;
              height: 18px;
              letter-spacing: 0.48px;
              line-height: 18px;
              padding-inline: 8px;
              text-transform: uppercase;
            }
          }

          .subHeader {
            color: $neutral-600;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }

    > button {
      align-items: center;
      background-color: transparent;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      display: flex;
      height: 36px;
      justify-content: center;
      outline: none;
      position: absolute;
      transition: transform 150ms ease-out;
      width: 36px;
      z-index: 1;

      > svg {
        fill: $green-400;
        height: 24px;
        width: 24px;
      }

      &.swap {
        transform: rotate(180deg);
      }

      &:disabled {
        > svg {
          fill: $neutral-400;
        }
      }
    }
  }

  .datePickerContainer {
    flex-shrink: 0;
    height: 64px;
    position: relative;
    width: 30%;

    .flightDateSelector {
      --flight-date-selector-focus-width: 656px;
    }
  }

  > button {
    align-items: center;
    background-color: $neutral-600;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    height: 22px;
    justify-content: center;
    outline: none;
    width: 22px;

    .icon {
      fill: $neutral-white;
      flex-shrink: 0;
      height: 18px;
      width: 18px;
    }

    &:hover,
    &:focus {
      background-color: $neutral-black;
    }

    &:disabled {
      background-color: $neutral-400;
    }
  }

  &.roundTrip {
    .datePickerContainer {
      width: 47%;
    }
  }

  &:dir(rtl) {
    direction: rtl;
  }

  @media only screen and (max-width: 1023px) {
    .placePickersContainer {
      .placePickerContainer {
        .inputSelector {
          --input-selector-focus-width: 480px;
        }
      }
    }

    .datePickerContainer {
      .flightDateSelector {
        --flight-date-selector-focus-width: 576px;
      }
    }

    &.roundTrip,
    &.oneWay {
      flex-wrap: wrap;

      .placePickersContainer {
        .placePickerContainer {
          .inputSelector {
            --input-selector-focus-width: 576px;

            &.inbound {
              --input-selector-focus-left: unset;
              --input-selector-focus-right: -16px;
              --input-selector-left: unset;
              --input-selector-right: 0;
              --input-selector-transform-origin: top right;
              --input-selector-transition: right 150ms ease-out, padding 150ms ease-out,
                top 150ms ease-out, width 150ms ease-out;

              &:dir(rtl) {
                --input-selector-transform-origin-rtl: top left;
                --input-selector-transition-rtl: left 150ms ease-out, padding 150ms ease-out,
                  top 150ms ease-out, width 150ms ease-out;
              }
            }
          }
        }
      }

      .datePickerContainer {
        width: 100%;

        .flightDateSelector {
          --flight-date-selector-focus-width: calc(100% + 32px);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .placePickersContainer {
      .placePickerContainer {
        .inputSelector {
          --input-selector-focus-width: 400px;
        }
      }
    }

    .datePickerContainer {
      .flightDateSelector {
        --flight-date-selector-focus-width: 496px;
      }
    }

    &.roundTrip {
      .placePickersContainer {
        .placePickerContainer {
          .inputSelector {
            --input-selector-focus-width: 496px;
          }
        }
      }
    }
  }
}
