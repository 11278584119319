@import '@styles/components/Color.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: pxToRem(388);

  .month {
    align-items: center;
    color: $neutral-900;
    display: flex;
    flex-shrink: 0;
    font-size: pxToRem(16);
    font-weight: 600;
    height: pxToRem(48);
    justify-content: center;
    line-height: pxToRem(24);
  }

  .dayLabels {
    align-items: center;
    display: flex;
    margin-bottom: pxToRem(2);

    > div {
      color: $neutral-600;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      padding: pxToRem(8) pxToRem(14);
      text-align: center;
      width: 100%;

      &:nth-child(n + 6) {
        color: $neutral-900;
        font-weight: 600;
      }
    }
  }

  .days {
    display: flex;
    flex-wrap: wrap;
    row-gap: pxToRem(4);
    width: 100%;

    > div {
      display: flex;
      gap: pxToRem(4);
      height: pxToRem(52);
      justify-content: center;
      width: pxToRem(56);

      &:nth-child(7n + 1) {
        justify-content: start;
        width: pxToRem(54);
      }

      &:nth-child(7n) {
        justify-content: end;
        width: pxToRem(54);
      }

      .date {
        align-items: center;
        border-radius: pxToRem(8);
        border: 1px solid transparent;
        color: $neutral-900;
        display: flex;
        flex-direction: column;
        font-size: pxToRem(16);
        gap: pxToRem(2);
        height: pxToRem(52);
        justify-content: center;
        line-height: pxToRem(20);
        padding: pxToRem(4) 0;
        width: pxToRem(52);

        &.publicHoliday {
          .dateNumber {
            &::after {
              color: $neutral-900;
              content: '*';
              position: relative;
              top: pxToRem(-2);
              margin-left: pxToRem(1);

              &:dir(rtl) {
                content: '*';
                margin-right: pxToRem(1);
                margin-left: 0;
              }
            }
          }
        }

        &.today {
          font-weight: 600;
        }

        .priceConatiner {
          align-items: center;
          display: flex;
          height: pxToRem(16);
          justify-content: center;
          font-size: pxToRem(12);
          line-height: pxToRem(16);
          color: $neutral-900;
        }

        &.selectable {
          &.variantA {
            &.cheapDate {
              background-color: $green-100;
            }

            &.expensiveDate {
              background-color: $red-100;
            }

            &.normalDate {
              background-color: $neutral-200;
            }
          }

          &.variantB {
            &.cheapDate {
              .priceConatiner {
                color: $green-500;
              }
            }

            &.expensiveDate {
              .priceConatiner {
                color: $red-500;
              }
            }
          }

          cursor: pointer;

          &:hover {
            border: 1px solid $neutral-600;
          }
        }

        &.disabled {
          color: $neutral-400;
        }
      }

      &.fromDate,
      &.toDate {
        position: relative;

        .date {
          background-color: $green-400;
          color: $neutral-white;

          .priceConatiner {
            color: $neutral-white;
          }
        }

        &.hasToDate {
          &::before {
            content: '';
            background-color: $green-100;
            height: 100%;
            position: absolute;
            width: 50%;
            z-index: -1;
          }
        }
      }

      &.fromDate {
        &.hasToDate {
          &::before {
            right: 0;

            &:dir(rtl) {
              left: 0;
              right: unset;
            }
          }
        }
      }

      &.toDate {
        &.hasToDate {
          &::before {
            left: 0;

            &:dir(rtl) {
              left: unset;
              right: 0;
            }
          }
        }
      }

      &.inRange {
        background-color: $green-100;
      }
    }
  }
}
