@import '@styles/components/Color.module.scss';
@import '@styles/functions/pxToRem.module.scss';
@import '@styles/mixins/Screen.module.scss';

.container {
  border-bottom: solid 1px $neutral-200;
  padding: 16px 32px;

  &.fixed {
    background-color: $neutral-white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
  }

  &.flightSearch2 {
    .flightSearchForm {
      max-width: pxToRem(704);

      @include md {
        max-width: pxToRem(944);
      }

      @include lg {
        max-width: pxToRem(1136);
      }
    }

    .flightComparisonProviders {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
    }
  }

  .flightSearchForm {
    color: $neutral-900;
    display: flex;
    flex-direction: column;
    gap: 16px 8px;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;

    .searchTypeAndFlightInformation {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .legSearches {
      display: flex;
      gap: 8px;
      flex-grow: 1;

      &.multiCityLegSearches {
        flex-direction: column;
      }
    }

    .searchButton {
      align-items: center;
      background-color: $green-400;
      border-radius: 8px;
      border: none;
      color: $neutral-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      height: 64px;
      line-height: 24px;
      outline: none;
      width: 144px;
      justify-content: center;

      @media screen and (max-width: 1279px) and (min-width: 1024px) {
        width: 64px;
      }

      .icon {
        width: 24px;
        height: 24px;
        fill: $neutral-white;
      }

      &.multiCitySearchButton {
        width: 144px;
        height: 48px;
      }

      &:hover {
        background-color: $green-500;
      }
    }

    .addLegAndFlightInformationAndSearchButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;

      .flightInformationAndSearchButton {
        display: flex;
        justify-content: space-between;

        &.fullWidth {
          width: 100%;
        }
      }

      .addLegSearch {
        align-items: center;
        background-color: $neutral-white;
        border-radius: 8px;
        border: 2px solid $green-400;
        color: $green-400;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        gap: 0 4px;
        height: 40px;
        line-height: 24px;
        outline: none;
        padding: 0 16px;
        position: relative;

        .tooltip {
          background-color: $neutral-900;
          border-radius: 8px;
          color: $neutral-white;
          display: none;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          padding: 8px 12px;
          position: absolute;
          top: 0;
          white-space: nowrap;
          z-index: 1;

          &.bottom {
            left: 50%;
            top: 100%;
            transform: translateX(-50%) translateY(10px);

            &::before {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid $neutral-900;
              content: '';
              left: 50%;
              position: absolute;
              top: 0;
              transform: translateX(-50%) translateY(-90%);
            }
          }
        }

        &::before {
          content: '+';
          transform: translate(0, -1px);
        }

        &:hover {
          background-color: $green-100;
        }

        &:disabled {
          background-color: $neutral-white;
          border-color: $neutral-300;
          color: $neutral-400;

          &:hover {
            .tooltip {
              display: block;
            }
          }
        }
      }
    }

    .flightComparisonProviders {
      align-items: center;
      border-radius: 0 0 8px 8px;
      display: flex;
      padding-left: 12px;
      min-height: 24px;

      .flightComparisonProvider {
        align-items: center;
        cursor: pointer;
        display: flex;

        .checkbox {
          --checkbox-background-color: #{$green-400};
          --checkbox-size: 24px;
          border-radius: 2px;
          flex-shrink: 0;
          margin: 0 8px;
        }
      }
    }
  }

  &.rtl {
    direction: rtl;
  }
}

.contentContainer {
  padding: 8px 32px 16px;
  background-color: $neutral-100;
  color: $neutral-900;
  min-height: 100%;
  min-height: calc(100% - 185px);
}

.content {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
