@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';
@import '@styles/components/Triangle.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  padding: 24px;

  &.flightSearch2 {
    .filtersAndSearchButton {
      font-size: pxToRem(14);

      .filters {
        .buttonFilters {
          .buttonFilter {
            font-size: pxToRem(14);
          }
        }
      }
    }

    .flightComparisonProviders {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
    }
  }

  .flightBtnFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 1023px) {
      display: block;
    }
  }

  .searchTypesAndAddLegSearchContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .searchTypes {
      align-items: center;
      display: flex;
      gap: 0 8px;

      > button {
        align-items: center;
        background-color: $neutral-200;
        border-radius: 100px;
        border: none;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        height: 40px;
        line-height: 24px;
        outline: none;
        padding: 0 16px;

        &.selected {
          background-color: $green-100;
          color: $green-500;
        }
      }
    }

    .addLegSearch {
      align-items: center;
      background-color: $neutral-white;
      border-radius: 8px;
      border: 2px solid $green-400;
      color: $green-400;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      gap: 0 4px;
      height: 40px;
      line-height: 24px;
      outline: none;
      padding: 0 16px;
      position: relative;

      .tooltip {
        background-color: $neutral-900;
        border-radius: 8px;
        color: $neutral-white;
        display: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
        white-space: nowrap;
        z-index: 1;

        &.bottom {
          left: 50%;
          top: 100%;
          transform: translateX(-50%) translateY(10px);

          &::before {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid $neutral-900;
            content: '';
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%) translateY(-90%);
          }
        }
      }

      &::before {
        content: '+';
        transform: translate(0, -1px);
      }

      &:hover {
        background-color: $green-100;
      }

      &:disabled {
        background-color: $neutral-white;
        border-color: $neutral-300;
        color: $neutral-400;

        &:hover {
          .tooltip {
            display: block;
          }
        }
      }
    }
  }

  .legSearches {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
  }

  .filtersAndSearchButton {
    align-items: center;
    display: flex;
    gap: 0 24px;

    .filters {
      align-items: center;
      display: flex;
      flex-wrap: wrap-reverse;
      flex: 1 1 auto;
      gap: 8px 24px;
      justify-content: space-between;

      .checkboxFilters {
        display: flex;
        gap: 0 24px;

        .checkboxFilter {
          align-items: center;
          cursor: pointer;
          display: flex;
          gap: 0 8px;
          white-space: nowrap;

          .checkbox {
            --checkbox-background-color: #{$green-400};
            --checkbox-size: 24px;
            border-radius: 2px;
            flex-shrink: 0;
          }
        }
      }

      .buttonFilters {
        display: flex;
        gap: 0 8px;

        .buttonFilter {
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          gap: 0 8px;
          height: 48px;
          line-height: 24px;
          padding: 0 12px;
          position: relative;
          text-transform: capitalize;
          white-space: nowrap;

          &:hover {
            background-color: $neutral-200;
          }

          &.expanded {
            background-color: transparent;
            cursor: default;

            .passengerPickerDropdown,
            .cabinClassPickerDropdown,
            .paymentTypePickerDropdown {
              opacity: 1;
              transform: scale(1);
            }
          }

          .triangle {
            @include triangle(6px);
            color: $neutral-900;
            flex-shrink: 0;
            margin: 0 6px;
          }

          .passengerPickerDropdown {
            background-color: $neutral-white;
            border-radius: 16px;
            box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
            display: flex;
            flex-direction: column;
            opacity: 0;
            overflow: hidden;
            padding: 24px 16px 18px;
            position: absolute;
            top: 0;
            transform: scale(0);
            transition: opacity 100ms ease-out, transform 150ms ease-out;
            z-index: 2;

            .minusSign {
              &::before {
                content: '\FF0D';
              }
            }

            .plusSign {
              &::before {
                content: '\FF0B';
              }
            }

            .passenger {
              align-items: center;
              display: flex;
              gap: 0 32px;
              justify-content: space-between;
              padding: 8px 0;

              .type {
                align-items: center;
                display: flex;
                gap: 0 8px;

                .icon {
                  @include search-form-icons;
                  flex-shrink: 0;
                }

                .label {
                  color: $neutral-900;
                  font-size: 16px;
                  line-height: 24px;
                }

                .requirement {
                  color: $neutral-600;
                  font-size: 12px;
                  line-height: 18px;
                  white-space: nowrap;
                }
              }

              .stepperContainer {
                align-items: center;
                display: flex;
                gap: 0 8px;

                > button {
                  background-color: $green-400;
                  border-radius: 4px;
                  border: none;
                  color: $neutral-white;
                  cursor: pointer;
                  height: 22px;
                  outline: none;
                  padding: 0;
                  width: 22px;

                  &.minusSign,
                  &.plusSign {
                    &::before {
                      align-items: center;
                      color: $neutral-white;
                      display: flex;
                      font-size: 16px;
                      font-weight: 600;
                      height: 22px;
                      justify-content: center;
                      width: 22px;
                    }
                  }

                  &:hover {
                    background-color: $green-500;
                  }

                  &:disabled {
                    background-color: $neutral-400;
                  }
                }

                .counter {
                  color: $neutral-900;
                  font-size: 16px;
                  line-height: 24px;
                  width: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .applyContainer {
              border-top: 1px solid $neutral-300;
              display: flex;
              justify-content: flex-end;
              margin-top: 8px;
              padding-top: 10px;

              > button {
                background-color: transparent;
                border: none;
                color: $green-400;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                margin: 0;
                outline: none;
                padding: 0;
                text-transform: capitalize;
              }
            }

            &.openFromLeft {
              left: 0;
              transform-origin: top left;
            }

            &.openFromRight {
              right: 0;
              transform-origin: top right;
            }
          }

          .cabinClassPickerDropdown {
            background-color: $neutral-white;
            border-radius: 16px;
            box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
            display: flex;
            flex-direction: column;
            opacity: 0;
            overflow: hidden;
            padding: 8px 0;
            position: absolute;
            right: 0;
            top: 0;
            transform-origin: top right;
            transform: scale(0);
            transition: opacity 100ms ease-out, transform 150ms ease-out;
            z-index: 2;

            .cabinClass {
              align-items: center;
              display: flex;
              font-size: 16px;
              font-weight: 400;
              height: 48px;
              line-height: 24px;
              padding: 0 24px;
              white-space: nowrap;

              &.selected {
                color: $green-500;
              }

              &:hover {
                background-color: $neutral-200;
              }
            }
          }

          .paymentTypePickerDropdown {
            background-color: $neutral-white;
            border-radius: 16px;
            box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
            display: flex;
            flex-direction: column;
            gap: 16px 0;
            opacity: 0;
            overflow: hidden;
            padding: 24px 24px 18px;
            position: absolute;
            right: 0;
            top: 0;
            transform-origin: top right;
            transform: scale(0);
            transition: opacity 100ms ease-out, transform 150ms ease-out;
            width: 400px;
            z-index: 2;
            white-space: normal;

            .message {
              color: $neutral-600;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
            }

            .paymentMethods {
              display: flex;
              flex-wrap: wrap;
              gap: 12px 12px;

              .paymentMethod {
                align-items: center;
                cursor: pointer;
                display: flex;
                gap: 0 8px;
                width: calc(50% - 8px);

                .checkbox {
                  --checkbox-background-color: #{$green-400};
                  --checkbox-size: 24px;
                  border-radius: 2px;
                  flex-shrink: 0;
                }

                .name {
                  @include ellipsis;
                }
              }
            }

            .showMore {
              color: $green-400;
              cursor: pointer;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              margin: 2px 0;
            }

            .applyContainer {
              border-top: 1px solid $neutral-300;
              display: flex;
              justify-content: flex-end;
              padding-top: 10px;

              > button {
                background-color: transparent;
                border: none;
                color: $green-400;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                margin: 0;
                outline: none;
                padding: 0;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .justifyEnd {
      justify-content: flex-end;
    }

    > button {
      align-items: center;
      background-color: $green-400;
      border-radius: 100px;
      border: none;
      color: $neutral-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      height: 48px;
      line-height: 24px;
      outline: none;
      padding: 0 40px;

      &:hover {
        background-color: $green-500;
      }
    }
  }

  .flightComparisonProviders {
    align-items: center;
    background-color: $neutral-200;
    border-radius: 0 0 8px 8px;
    display: flex;
    height: 56px;
    margin: 8px -24px -24px;
    padding: 0 16px;

    .flightComparisonProvider {
      align-items: center;
      cursor: pointer;
      display: flex;

      .checkbox {
        --checkbox-background-color: #{$green-400};
        --checkbox-size: 24px;
        border-radius: 2px;
        flex-shrink: 0;
        margin: 0 8px;
      }
    }
  }

  &.rtl {
    direction: rtl;

    .filtersAndSearchButton {
      .filters {
        .buttonFilters {
          .buttonFilter {
            .cabinClassPickerDropdown {
              left: 0;
              right: unset;
              transform-origin: top left;
            }

            .paymentTypePickerDropdown {
              left: 0;
              right: unset;
              transform-origin: top left;
            }
          }
        }
      }
    }
  }
}
